import React from 'react';
import './Projects.css';

const Projects = () => {
  return (
    <div className="projects-section">
      <div className="header">
        <h1 className="projects-heading">Projects</h1>
      </div>
      <div className="projects-container">
        {/* Project A */}
        <div className="project-card">
          <h2 className="project-title">SARO - The E-Commerce Site</h2>
          <p><strong>Technology Used:</strong> MERN Stack (MongoDB, Express, React, Node)</p>
          <p><strong>Description:</strong> An e-commerce platform offering seamless shopping experiences with secure payment and an intuitive UI.</p>
          <iframe
            src="https://arorabsm.netlify.app/"
            title="SARO E-Commerce"
            className="project-iframe"
            frameBorder="0"
          ></iframe>
        </div>

        {/* Project B */}
        <div className="project-card">
          <h2 className="project-title">VyapaarX - The Business Management System</h2>
          <p><strong>Technology Used:</strong> Django, PostgreSQL, Bootstrap</p>
          <p><strong>Description:</strong> A business management system to streamline inventory, invoicing, and employee management efficiently.</p>
          <iframe
            src="https://aroratech.netlify.app/"
            title="VyapaarX"
            className="project-iframe"
            frameBorder="0"
          ></iframe>
        </div>

        {/* Project C */}
        <div className="project-card">
          <h2 className="project-title">Heartlink - The Matrimonial Site</h2>
          <p><strong>Technology Used:</strong> MERN Stack</p>
          <p><strong>Description:</strong> A matrimonial site with advanced matching algorithms to connect individuals.</p>
          <p><strong>Status:</strong> Coming Soon...</p>
        </div>
      </div>
    </div>
  );
};

export default Projects;
