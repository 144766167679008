import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';  // Import routing components
import './App.css';
import Blogs from './components/Blogs';
import ContactAndSocialMedia from './components/Contact';
import Education from './components/Education';
import Footer from './components/Footer';
import LandingPage from './components/LandingPage';
import Navbar from './components/Navbar';
import Projects from './components/Projects';
import Resume from './components/Resume';
import Skills from './components/Skills';

function App() {
  return (
    <Router>
      <Routes>
        {/* Route for the landing page */}
        <Route path="/" element={<LandingPage />} />

        {/* Route for Resume page */}
        <Route path="/resume" element={<><Navbar /><Resume /><Footer /></>} />

        {/* Route for Education page */}
        <Route path="/education" element={<><Navbar /><Education /><Footer /></>} />

        {/* Route for Skills page */}
        <Route path="/skills" element={<><Navbar /><Skills /><Footer /></>} />

        {/* Route for Blogs page */}
        <Route path="/blogs" element={<><Navbar /><Blogs /><Footer /></>} />

        {/* Route for Projects page */}
        <Route path="/projects" element={<><Navbar /><Projects /><Footer /></>} />

        {/* Route for Contact page */}
        <Route path="/contact" element={<><Navbar /><ContactAndSocialMedia /><Footer /></>} />
      </Routes>
    </Router>
  );
}

export default App;
