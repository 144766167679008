import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import './Education.css';
import cbse from './cbse.png';

const Education = () => {
  return (
    <div className="education-section">
      <div className="header">
        <h1 className="education-heading">Education</h1>
      </div>
      <div className="education-cards">
        <Row className="justify-content-center">
          {/* University Education Card */}
          <Col xs={12} md={10} className="mb-4">
            <Card className="education-card">
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <Card.Title className="card-title">Graduation</Card.Title>
                    <Card.Text>
                      <strong>Board/University:</strong> Lovely Professional University<br />
                      <strong>Degree:</strong> Bachelor of Technology (B.Tech) in Computer Science and Engineering<br />
                      <strong>Grade:</strong> 8.09 CGPA<br />
                      <strong>Years:</strong> 2022 - Present<br />
                      <strong>Location:</strong> Phagwara, Punjab, India
                    </Card.Text>
                  </Col>
                  <Col md={4} className="text-center">
                    <img
                      src='https://ums.lpu.in/assets/img/logos/lpu-logo.svg'// Add the logo path here
                      alt="University Logo"
                      className="university-logo"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          {/* 12th Education Card */}
          <Col xs={12} md={10} className="mb-4">
            <Card className="education-card">
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <Card.Title className="card-title">12th Education</Card.Title>
                    <Card.Text>
                      <strong>Board/University:</strong> Central Board of Secondary Education (CBSE), New Delhi<br />
                      <strong>Degree:</strong> Senior Secondary Education (12th)<br />
                      <strong>Grade:</strong> 77%<br />
                      <strong>Years:</strong> 2021 - 2022<br />
                      <strong>Location:</strong> Kapurthala, Punjab, India
                    </Card.Text>
                  </Col>
                  <Col md={4} className="text-center">
                    <img
                      src={cbse} // Add the logo path here
                      alt="12th Education Logo"
                      className="education-logo"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>

          {/* 10th Education Card */}
          <Col xs={12} md={10} className="mb-4">
            <Card className="education-card">
              <Card.Body>
                <Row>
                  <Col md={8}>
                    <Card.Title className="card-title">10th Education</Card.Title>
                    <Card.Text>
                      <strong>Board/University:</strong> Central Board of Secondary Education (CBSE), New Delhi<br />
                      <strong>Degree:</strong> Secondary Education (10th)<br />
                      <strong>Grade:</strong> 97%<br />
                      <strong>Years:</strong> 2019 - 2020<br />
                      <strong>Location:</strong> Kapurthala, Punjab, India
                    </Card.Text>
                  </Col>
                  <Col md={4} className="text-center">
                    <img
                      src={cbse} // Add the logo path here
                      alt="10th Education Logo"
                      className="education-logo"
                    />
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Education;
