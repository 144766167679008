import React from 'react';
import './Blogs.css';

const Blogs = () => {
  return (
    <div className="blogs-section">
      <h1 className="blogs-heading">Blogs</h1>
      <div className="coming-soon">
        <h2>Coming Soon...</h2>
      </div>
    </div>
  );
};

export default Blogs;
