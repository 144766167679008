import React from "react";
import "./Resume.css";

const Resume = () => {
  return (
    <div className="resume-page">
      <div className="resume-title-container">
        <h1 className="resume-title">CV / Resume</h1>
      </div>
      <div className="resume-iframe-container">
        <iframe
          src="/resume.pdf"
          title="Shivam Arora CV"
          className="resume-iframe"
        ></iframe>
      </div>
      <div className="resume-button-container">
        <a
          href="/resume.pdf"
          download="Shivam_Arora_Resume.pdf"
          className="animated-button"
        >
          Download CV / Resume
        </a>
      </div>
    </div>
  );
};

export default Resume;
