import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './LandingPage.css';

const LandingPage = () => {
    return (
        <div className="landing-page">
            <div className="center-box" style={{ textAlign: 'left' }}>
                <h1>
                    <span>Hi,</span><br />
                    <span>Welcome to The</span> <span className="highlight">Shivam Arora</span> <span>Arena</span>
                </h1>
            </div>
            <div className="dual-box">
                <div className="left-section">
                    <h2>Turning ideas into impactful solutions</h2>
                    <p>Full-Stack Developer | Problem Solver | Lifelong Learner</p>
                    <hr className="divider" />
                    <p className="intro-text">
                        Hi, I'm Shivam Arora, a passionate Computer Science and Engineering student from Lovely Professional University, Punjab. With expertise in ReactJS, problem-solving, and full-stack development, I thrive on crafting efficient, user-friendly solutions. Whether it's building seamless web applications or optimizing algorithms, I love tackling challenges that push me to grow.<br /><br />
                        I'm driven by a vision to create a positive impact through technology while continuously evolving as a developer and individual. Let’s build something extraordinary together!
                    </p>
                </div>
                <div className="right-section">
                    <div className="profile-picture" style={{ width: '350px', height: '350px' }}></div>
                </div>
            </div>
            
            {/* Buttons Section */}
            <div className="buttons-container">
                <Link to="/resume">
                    <button className="landing-button">CV/Resume</button>
                </Link>
                <Link to="/projects">
                    <button className="landing-button">Projects</button>
                </Link>
                <Link to="/education">
                    <button className="landing-button">Education</button>
                </Link>
                <Link to="/skills">
                    <button className="landing-button">Skills</button>
                </Link>
                <Link to="/blogs">
                    <button className="landing-button">Blogs</button>
                </Link>
                <Link to="/contact">
                    <button className="landing-button">Contact</button>
                </Link>
            </div>
        </div>
    );
};

export default LandingPage;
