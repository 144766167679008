import React from 'react';
import hr from './hr.png';
import './Contact.css';

const ContactAndSocialMedia = () => {
  const socialMediaProfiles = [
    {
      name: "LinkedIn",
      logo: "https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png",
      link: "https://www.linkedin.com/in/therealshivamarora/",
    },
    {
      name: "LeetCode",
      logo: "https://upload.wikimedia.org/wikipedia/commons/1/19/LeetCode_logo_black.png",
      link: "https://leetcode.com/u/shivam_arora99/",
    },
    {
      name: "GitHub",
      logo: "https://upload.wikimedia.org/wikipedia/commons/9/91/Octicons-mark-github.svg",
      link: "https://github.com/realshivamarora",
    },
    {
      name: "HackerRank",
      logo: hr,
      link: "https://www.hackerrank.com/profile/shivamarorakapu1",
    },
    {
      name: "Code 360",
      logo: "https://files.codingninjas.in/new-cn-logos-1-1711622387.svg", // Replace with real logo URL
      link: "https://www.naukri.com/code360/profile/realshivamarora",
    },
    {
      name: "Mail",
      logo: "https://upload.wikimedia.org/wikipedia/commons/4/4e/Gmail_Icon.png",
      link: "mailto:shivam.arora.contact@gmail.com",
    },
  ];

  return (
    <div className="contact-section">
      <div className="header">
        <h1 className="contact-heading">Contact and Social Media</h1>
      </div>
      <div className="social-cards">
        {socialMediaProfiles.map((profile, index) => (
          <a
            href={profile.link}
            target="_blank"
            rel="noopener noreferrer"
            className="social-card"
            key={index}
          >
            <img
              src={profile.logo}
              alt={`${profile.name} Logo`}
              className="social-logo"
            />
            <p className="social-name">{profile.name}</p>
          </a>
        ))}
      </div>
    </div>
  );
};

export default ContactAndSocialMedia;
