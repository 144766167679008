import React, { useState, useEffect } from "react";
import { FaPhoneAlt, FaEnvelope, FaLinkedin } from "react-icons/fa"; // Import icons
import "./Footer.css";

const Footer = () => {
  const [currentText, setCurrentText] = useState("Fearless");
  const currentYear = new Date().getFullYear(); // Get the current year dynamically
  const texts = ["Fearless", "Shivam Arora", `Copyright - ${currentYear} | Arora Tech`]; // Use dynamic year here
  const delay = 3000; // Delay for text cycle in milliseconds

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prevText) => {
        const currentIndex = texts.indexOf(prevText);
        return texts[(currentIndex + 1) % texts.length];
      });
    }, delay);

    return () => clearInterval(interval); // Cleanup interval on component unmount
    // eslint-disable-next-line
  }, []); // Empty array ensures this effect runs only once

  return (
    <footer className="footer">
      <div className="footer-left">
        <div className="footer-text">{currentText}</div>
      </div>
      <div className="footer-right">
        <div className="social-icons">
          <a href="tel:+919815366293" className="social-icon">
            <FaPhoneAlt />
          </a>
          <a href="mailto:shivam.arora.contact@gmail.com" className="social-icon">
            <FaEnvelope />
          </a>
          <a href="https://www.linkedin.com/in/therealshivamarora/" className="social-icon">
            <FaLinkedin />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
