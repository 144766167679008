import React from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './Navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar">
            <ul className="navbar-buttons">
                <li className="navbar-button">
                    <Link to="/">Home</Link>
                </li>
                <li className="navbar-button">
                    <Link to="/resume">CV/Resume</Link>
                </li>
                <li className="navbar-button">
                    <Link to="/education">Education</Link>
                </li>
                <li className="navbar-button">
                    <Link to="/skills">Skills</Link>
                </li>
                <li className="navbar-button">
                    <Link to="/projects">Projects</Link>
                </li>
                <li className="navbar-button">
                    <Link to="/blogs">Blogs</Link>
                </li>
                <li className="navbar-button">
                    <Link to="/contact">Contact</Link>
                </li>
            </ul>
            <div className="navbar-right">
                <span>Shivam Arora</span>
            </div>
        </nav>
    );
};

export default Navbar;
